var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Sandbox" }, [
    _c("h1", { staticClass: "mb-30" }, [_vm._v("Pagination Test View")]),
    _c(
      "div",
      { staticClass: "Sandbox__pagination" },
      [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model.trim.number",
              value: _vm.pagination.count,
              expression: "pagination.count",
              modifiers: { trim: true, number: true }
            }
          ],
          staticClass: "mb-30",
          attrs: { "data-cy": "sandbox-pagination-count-input" },
          domProps: { value: _vm.pagination.count },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(
                _vm.pagination,
                "count",
                _vm._n($event.target.value.trim())
              )
            },
            blur: function($event) {
              return _vm.$forceUpdate()
            }
          }
        }),
        _vm._l(_vm.displayedPaginationData, function(item, index) {
          return _c(
            "div",
            { key: index, staticClass: "Sandbox__test-item mb-20" },
            [
              _c(
                "label",
                {
                  staticClass: "Sandbox__test-item-label",
                  attrs: { "data-cy": "pagination-test-item-" + index }
                },
                [_vm._v("\n        " + _vm._s(item.name) + "\n      ")]
              )
            ]
          )
        }),
        _c("base-pagination", {
          staticClass: "mt-30",
          attrs: {
            "item-count": _vm.pagination.count,
            page: _vm.pagination.page,
            "request-limit": _vm.pagination.requestLimit
          },
          on: { paginate: _vm.paginate }
        })
      ],
      2
    ),
    _c(
      "div",
      { attrs: { "data-cy": "sandbox-base-toggle" } },
      [
        _c(
          "base-button",
          {
            staticClass: "bg-light fc-white",
            attrs: {
              "data-cy": "sandbox-base-toggle-update-active-option-btn"
            },
            on: { click: _vm.updateToggleActiveOption }
          },
          [_vm._v("\n      Update active option\n    ")]
        ),
        _c(
          "base-button",
          {
            staticClass: "bg-light fc-white",
            attrs: { "data-cy": "sandbox-base-toggle-update-options-btn" },
            on: { click: _vm.updateToggleOptions }
          },
          [_vm._v("\n      Update toggle's options\n    ")]
        ),
        _c(
          "base-button",
          {
            staticClass: "bg-light fc-white",
            attrs: { "data-cy": "sandbox-base-toggle-update-label-btn" },
            on: { click: _vm.updateToggleLabel }
          },
          [_vm._v("\n      Update toggle's label\n    ")]
        ),
        _c(
          "base-button",
          {
            staticClass: "bg-light fc-white",
            attrs: { "data-cy": "sandbox-base-toggle-update-disabled-btn" },
            on: { click: _vm.updateToggleDisabled }
          },
          [_vm._v("\n      Update toggle's disabled\n    ")]
        ),
        _c(
          "label",
          { attrs: { "data-cy": "sandbox-base-toggle-emitted-value" } },
          [_vm._v(_vm._s(_vm.baseToggle.emitted))]
        ),
        _c("base-toggle", {
          attrs: {
            "active-option": _vm.baseToggle.activeOption,
            "data-cy": "sandbox-base-toggle-toggle",
            disabled: _vm.baseToggle.disabled,
            label: _vm.baseToggle.label,
            toggles: _vm.baseToggle.options
          },
          on: {
            toggle: function($event) {
              _vm.baseToggle.activeOption = $event
              _vm.baseToggle.emitted = $event
            }
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }